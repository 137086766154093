import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'amfe-flexible'
import Vant from 'vant'
import 'vant/lib/index.css'
import axios from 'axios'
import './assets/styles/global.css'
import { Dialog } from 'vant'
import Carousel3d from 'vue-carousel-3d'
import Meta from 'vue-meta';
import 'swiper/dist/css/swiper.min.css';
import 'swiper/dist/js/swiper.min';
// import './registerServiceWorker.js'
Vue.use(Vant)
Vue.use(Dialog)
Vue.use(Carousel3d)
Vue.use(Meta)
Vue.config.productionTip = false

new Vue({
  axios,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')

// 禁用F12
window.onkeydown = window.onkeyup = window.onkeypress = function (event) {
  // 判断是否按下F12，F12键码为123
  if (event.keyCode == 123) {
  event.preventDefault(); // 阻止默认事件行为
  window.event.returnValue = false;
  }
}

// //禁用调试工具
// var threshold = 160; // 打开控制台的宽或高阈值
// // 每秒检查一次
// var check = setInterval(function() {
//     if (window.outerWidth - window.innerWidth > threshold || 
//         window.outerHeight - window.innerHeight > threshold) {
//         // 如果打开控制台，则刷新页面
//         window.location.reload();
//     }
// }, 1000)

//屏蔽右键菜单
// document.oncontextmenu = function (event){
//   if(window.event){
//   event = window.event;
//   }
//   try{
//   var the = event.srcElement;
//       if (!((the.tagName == "INPUT" && the.type.toLowerCase() == "text") || the.tagName == "TEXTAREA")){
//       return false;
//       }
//       return true;
//   }
//   catch (e){
//       return false;
//   }
// }