<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
    <Loading v-show="LOADING"></Loading>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import Loading from '@/components/Loading.vue'
export default {
  computed: {
    ...mapState(['LOADING']),
  },
  components: { Loading },
}
</script>

<style lang="less">
@import './assets/font/font.css';
body, h1, h2, h3, h4, h5, h6, hr, p, blockquote, /* structural elements 结构元素 */
dl, dt, dd, ul, ol, li, /* list elements 列表元素 */
pre, /* text formatting elements 文本格式元素 */
fieldset, lengend, button, input, textarea, /* form elements 表单元素 */
th, td {
  /* table elements 表格元素 */
  margin: 0;
  padding: 0;
}
//  #app {
//     font-family: HelveticaNeue;
//     font-weight: 400;
//   }

#app {
  font-family: HelveticaNeue;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // color: #2c3e50;
}
.van-loading {
  position: fixed !important;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000 !important;
}
</style>
