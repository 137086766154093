export default {
  /**接口请求的基础路径 */
  baseUrl: {
    // dev: 'http://192.168.1.23:8088', // 开发路径
    dev: 'https://aceiteach.hk',
    pro: 'https://aceiteach.hk', // 生产路径
  },
  AIurl: 'https://aceiteach.hk/api/writing/analyse',
  AITeacher: 'https://aceiteach.hk/api/ai/aiTeacher',
  PDFurl: 'https://192.168.1.23:8088/index/index/ai',
  uploadUrl: 'https://aceiteach.hk/api/upload/image', //圖片上傳
  // uploadUrl: 'http://192.168.1.23:8088/api/upload/image', //圖片上傳
}
 