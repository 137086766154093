import Vue from 'vue'
import VueRouter from 'vue-router'
import router_modular from '@/router/router_modular/index.js'

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}
Vue.use(VueRouter)

const routes = [...router_modular]

const router = new VueRouter({
  mode: 'hash', // 去掉url中的#
  routes,
  scrollBehavior: () => ({ y: 0 }),
})
//路由白名單
const whiteList = ['/login', 'register'] // no redirect whitelist
//全局路由守卫
router.beforeEach((to, from, next) => {
  if (to.matched.length === 0) {
    from.path ? next({ name: from.name }) : next('*')
  } else {
    next()
  }
  next()
})
router.afterEach((to, from) => {
  // console.log(to, from)
})
export default router
