//获取token
export function getToken() {
  return localStorage.getItem('token')
}
//设置token
export function setToken(token) {
  return localStorage.setItem('token', token)
}
//删除token
export function removeToken() {
  return localStorage.removeItem('token')
}
//設置用戶信息
export function setUserInfo(user) {
  return localStorage.setItem('userInfo', user)
}
//獲取用戶信息
export function getUserInfo() {
  return localStorage.getItem('userInfo')
}
//設置科目
export function setSubject(name) {
  return localStorage.setItem('subject', name)
}
//獲取科目
export function getSubject() {
  return localStorage.getItem('subject')
}
