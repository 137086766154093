/****   request.js   ****/
// 导入axios
import axios from 'axios'
import config from '@/config/index'
import { getToken, removeToken,getSubject } from '@/utils/auth'
import router from '@/router'
import { Toast } from 'vant'
import store from '@/store'
const baseUrl =
  process.env.NODE_ENV === 'development'
    ? config.baseUrl.dev
    : config.baseUrl.pro
//1. 创建新的axios实例，
const service = axios.create({
  // 公共接口
  baseURL: baseUrl,
  // 超时时间 单位是ms，
  timeout: 50 * 1000,
})
// 2.请求拦截器
service.interceptors.request.use(
  (req) => {
    store.commit('showLoading')
    req.data = JSON.stringify(req.data) //数据转化
    const token = getToken() || '' //獲取token
    const subject =getSubject() || '{}' 
    
    const subject_id=JSON.parse(subject).id
    req.headers = {
      'Content-Type': 'application/json', //配置请求头
    }
    if (token) {
      req.headers['token'] = token
      req.headers['subject-id'] = subject_id
    }
    return req
  },
  (error) => {
    console.log(error,111111111111111);
    store.commit('hideLoading')
    Promise.reject(error)
  }
)

// 3.响应拦截器
service.interceptors.response.use(
  (response) => {
    // console.log(1,1111111111111111);
    store.commit('hideLoading')
    //接收到响应数据并成功后的一些共有的处理，关闭loading等
    if (response.data.code === 401) {
      // Toast.fail('未登錄或登錄過期,請重新登錄')
      // window.location.href = '/login'
      router.replace({ path: '/login' })
      removeToken()
    }
    return response.data
  },
  (error) => {
    store.commit('hideLoading')
    if (error.response) {
      switch (error.response.status) {
        case 500:
          Toast.fail('服務器出錯')
          break
        case 403:
          Toast.fail('权限不足')
          break
        case 401:
          // Toast.fail('未登錄或登錄過期,請重新登錄')
          // window.location.href = '/login'
          router.replace({ path: '/login' })
          removeToken()
          break
        case 404:
          Toast.fail('请求地址不存在')
          break
        case 405:
          Toast.fail('请求方法不正确')
          break
        case 408:
          Toast.fail('请求超时')
          break
        case 502:
          Toast.fail('网关错误')
          break
        case 503:
          Toast.fail('服务不可用')
          break
        case 504:
          Toast.fail('网关超时')
          break
        case 505:
          Toast.fail('HTTP版本不受支持')
          break
        default:
          Toast.fail('未知錯誤，請聯係系統管理員')
          break
      }
    }
    return Promise.resolve(error.response)
  }
)
// 通用下载方法
// export function download(url, params, filename, config) {
//   downloadLoadingInstance = Loading.service({
//     text: '正在下载数据，请稍候',
//     spinner: 'el-icon-loading',
//     background: 'rgba(0, 0, 0, 0.7)',
//   })
//   return service
//     .post(url, params, {
//       transformRequest: [
//         (params) => {
//           return tansParams(params)
//         },
//       ],
//       headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
//       responseType: 'blob',
//       ...config,
//     })
//     .then(async (data) => {
//       const isBlob = blobValidate(data)
//       if (isBlob) {
//         const blob = new Blob([data])
//         saveAs(blob, filename)
//       } else {
//         const resText = await data.text()
//         const rspObj = JSON.parse(resText)
//         const errMsg =
//           errorCode[rspObj.code] || rspObj.msg || errorCode['default']
//         Message.error(errMsg)
//       }
//       downloadLoadingInstance.close()
//     })
//     .catch((r) => {
//       console.error(r)
//       Message.error('下载文件出现错误，请联系管理员！')
//       downloadLoadingInstance.close()
//     })
// }

export default service
