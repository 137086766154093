import Vue from 'vue'
import Vuex from 'vuex'
import store_modular from '@/store/modules/index.js'
import user from './modules/user'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    LOADING: false,
  },
  getters: {},
  mutations: {
    showLoading(state) {
      state.LOADING = true
    },
    hideLoading(state) {
      state.LOADING = false
    },
  },
  actions: {},
  modules: {
    store_modular,
    user,
  },
})
