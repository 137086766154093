import userApi from '@/api/user'
import {
  setUserInfo,
  setSubject,
  getToken,
  setToken,
  removeToken,
} from '@/utils/auth'

const state = {
  token: '',
  name: '',
  avatar: '',
  //阶段
  grade: '',
  //班級
  classes: '',
  //科目
  subject: '',
  //金幣
  money: '0',
}
const mutations = {
  //设置token
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  //设置账户
  SET_NAME: (state, name) => {
    state.name = name
  },
  //设置头像
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  //设置学习阶段
  SET_GRADE: (state, grade) => {
    state.grade = grade
  },
  //设置班级
  SET_CLASSES: (state, classes) => {
    state.classes = classes
  },
  //设置科目
  SET_SUBJECT: (state, subject) => {
    state.subject = subject
  },
  //设置金幣
  SET_MONEY: (state, money) => {
    state.money = money
  },
}
const actions = {
  //登录
  Login({ commit }, userInfo) {
    const name = userInfo.account.trim()
    const password = userInfo.password
    return new Promise((resolve, reject) => {
      userApi
        .login({ account: name, password: password })
        .then((res) => {
          commit('SET_TOKEN', res.data.token)
          setToken(res.data.token)
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  //退出登錄
  LoginOut({ commit, state }) {
    return new Promise((resolve, reject) => {
      userApi
        .logOut()
        .then((res) => {
          commit('SET_TOKEN', '')
          removeToken()
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  //注册
  Register({ commit }, userInfo) {
    const account = userInfo.account.trim()
    const password = userInfo.password
    const password_confirm = userInfo.password_confirm
    const mobile = userInfo.mobile
    const email = userInfo.email
    return new Promise((resolve, reject) => {
      userApi
        .register({ account, password, password_confirm, mobile, email })
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  //獲取用戶信息
  GetUserInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      userApi
        .getUserInfo()
        .then((res) => {
          const user = res.data
          const avatar =
            user.avatar == '' || user.avatar == null
              ? require('@/assets/img/acture.png')
              : user.avatar
          const grade =
            user.learning == '' ||
            user.learning == null ||
            user.learning.phase_id == '' ||
            user.learning.phase_id == null
              ? '未選擇學習階段'
              : user.learning.phase_id
          const classes =
            user.learning == '' ||
            user.learning == null ||
            user.learning.class_id == '' ||
            user.learning.class_id == null
              ? '未選擇年級'
              : user.learning.class_id
          const subject =
            user.learning == '' ||
            user.learning == null ||
            user.learning.subject == '' ||
            user.learning.subject == null
              ? '未選擇科目'
              : user.learning.subject
          commit('SET_NAME', user.nickname)
          commit('SET_AVATAR', avatar)
          commit('SET_GRADE', grade)
          commit('SET_CLASSES', classes)
          commit('SET_SUBJECT', subject)
          commit('SET_MONEY', user.user_money)
          setUserInfo(JSON.stringify(user))
          setSubject(JSON.stringify(subject))
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  //年級、班級、科目
  SetUserLearning() {
    new Promise((resolve, reject) => {
      userApi.setUserLearning().then((res) => {
        console.log(res)
      })
    })
  },
}
const getters = {}
const user = {
  namespaced: true, //开启命名空间，命名空间是为了解决方法可能出现命名重复的问题
  state,
  getters,
  actions,
  mutations,
}
export default user
